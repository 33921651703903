












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REMOVE_DEPARTMENT, UPDATE_DEPARTMENT } from '../store';
import { Department, RemoveDepartment, UpdateDepartment } from '../types';
import { ApiResponse } from '@/components/types';
import { MapDepartmentToUpdateDepartment } from '@/modules/conservatorshipToContact/model';

const Conservatorship = namespace('conservatorshipToContact');

@Component({})
export default class UpdateDepartmentDialog extends Vue {
  @Prop({ type: Object }) department?: Department | null;
  @Prop({ type: Boolean, default: false }) showType!: boolean;
  @Conservatorship.Action(UPDATE_DEPARTMENT) updateDepartment!: (department: UpdateDepartment) => Promise<ApiResponse>;
  @Conservatorship.Action(REMOVE_DEPARTMENT) removeContact!: (department: RemoveDepartment) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: UpdateDepartment = {
    contact_id: '',
    conservatorship_id: '',
    type: 'all',
    customer_number: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.value = {
        contact_id: '',
        conservatorship_id: '',
        type: 'all',
        customer_number: ''
      };

      this.error = null;
      this.$emit('update:department', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('department')
  watchContact(department: Department | null) {
    if (!department) return;

    this.value = MapDepartmentToUpdateDepartment(department);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    this.error = null;

    return this.updateDepartment(this.value);
  }

  remove() {
    if (!this.department) return;

    this.error = null;

    return this.removeContact({
      contact_id: this.department.id,
      conservatorship_id: this.department.conservatorshipId,
      type: this.department.type
    });
  }
}
